import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid md:grid-cols-2 gap-x-5 gap-y-0" }
const _hoisted_2 = { class: "auth-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_auth = _resolveComponent("auth")!

  return (_openBlock(), _createBlock(_component_auth, {
    class: "auth-register",
    title: _ctx.trans('register.title'),
    "submit-button-text": _ctx.trans('register.create_account'),
    "submit-button-loading-text": _ctx.trans('register.creating_account'),
    loading: _ctx.loading,
    onSubmit: _ctx.submit
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.trans("register.already_have_an_account")) + " ", 1),
          _createVNode(_component_router_link, {
            to: _ctx.goTo(_ctx.RouteNames.Login),
            class: "text-theme"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("register.sign_in")), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_form_group, {
          required: "",
          label: _ctx.trans('register.field.company_name'),
          modelValue: _ctx.formData.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
          "invalid-message": _ctx.validationErrors.name
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          required: "",
          label: _ctx.trans('register.field.company_email'),
          modelValue: _ctx.formData.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.email) = $event)),
          "invalid-message": _ctx.validationErrors.email
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          "input-type": "password",
          required: "",
          label: _ctx.trans('register.field.password'),
          modelValue: _ctx.formData.password,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.password) = $event)),
          "invalid-message": _ctx.validationErrors.password
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          "input-type": "password",
          required: "",
          label: _ctx.trans('register.field.password_confirmation'),
          modelValue: _ctx.formData.password_confirmation,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.password_confirmation) = $event)),
          "invalid-message": _ctx.validationErrors.password_confirmation
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          required: "",
          label: _ctx.trans('register.field.phone_number'),
          modelValue: _ctx.formData.phone_number,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.phone_number) = $event)),
          "invalid-message": _ctx.validationErrors.phone_number
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('register.field.company_website'),
          modelValue: _ctx.formData.website,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.website) = $event)),
          "invalid-message": _ctx.validationErrors.website
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          class: "col-span-2",
          label: _ctx.trans('register.field.siupal_or_siuppak'),
          modelValue: _ctx.formData.siupal_or_siuppak,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.siupal_or_siuppak) = $event)),
          "invalid-message": _ctx.validationErrors.siupal_or_siuppak
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          class: "col-span-2",
          required: "",
          "input-type": "textarea",
          textarea: "",
          label: _ctx.trans('register.field.company_address'),
          modelValue: _ctx.formData.address,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.address) = $event)),
          "invalid-message": _ctx.validationErrors.address
        }, null, 8, ["label", "modelValue", "invalid-message"])
      ])
    ]),
    _: 1
  }, 8, ["title", "submit-button-text", "submit-button-loading-text", "loading", "onSubmit"]))
}